import React, { useState, useRef, useCallback, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Modal, Spin, message } from "antd";
import "antd/dist/antd.css";
import FrontLeft from "./frontLeft";
import FrontRight from "./frontRight";
import BackRight from "./backRight";
import BackLeft from "./backLeft";
import Validate from "./validate";
import InspectionStamps from "./inspectionStamps";
import VehicleRegistration from "./vehicleRegistration";
import Speedometer from "./Speedometer";
import ViewAndComplete from "./viewAndComplete";
import Helper from "./../../extra/helper";
import MDHuongdan from "./modal/mdUserManual";
import Service from "./../../extra/getAPI";
import warning from "./images/warning-2.png";
import "./css/style.css";
export const AuthContext = React.createContext();
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 25,
      // color: "#009B4B",
      color: "#FB7800",
    }}
    spin
  />
);

const Index = (props) => {
  const [isModalVisibleFail, setIsModalVisibleFail] = useState(false);
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [isModalWarning, setIsModalWarning] = useState(false);
  // Ẩn hiện modal hướng dẫn chụp hình
  const [isModalVisible, setIsModalVisible] = useState(false);
  // Danh sách hình úp lên G2
  const [listImg, setListIMG] = useState({});
  const [loading, setLoading] = useState(false);
  // hình ảnh bổ sung được
  const [other, setOther] = useState([]);
  const [documentCar, setDocumentCar] = useState([]);
  const [messErrApi, setMessErrApi] = useState(null);
  // Hiện các bước chụp hình
  const [step, setStep] = useState(0);
  const [statusCheckOrder, setStatusCheckOrder] = useState(5);
  const [carNameOrder, setCarNameOrder] = useState(null);
  // nếu 1 là xe mới 2 là xe củ 3 là xe tái tục
  const [registeredType, setRegisteredType] = useState();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    CheckOrder();
  }, []);

  const CheckOrder = (e) => {
    const order = Helper.getParamUrlByKey("orderCode");
    Service.apicall(
      "GET",
      `api/v1/product/phy-damage-cov/${order}/check-upload`
    )
      .then((res) => {
        if (res.status === 200) {
          setStatusCheckOrder(res.data.result.status);
          if (+res.data.result.status === 1 || +res.data.result.status === 4) {
            setRegisteredType(+res.data.result.registeredType);
            switch (+res.data.result.registeredType) {
              case 2:
                setStep(1);
                setCarNameOrder(res.data.result?.carTitle);
                setIsModalVisible(true);
                break;
              case 1:
                setStep(8);
                setCarNameOrder(res.data.result?.carTitle);
                setIsModalVisible(false);
                break;
              case 3:
                setStep(8);
                setCarNameOrder(res.data.result?.carTitle);
                setIsModalVisible(false);
                break;
              default:
                break;
            }
          }
          if (+res.data.result.status === 5) {
            setShowConfirm(true);
          }
        } else {
          setStatusCheckOrder(6);
        }
      })
      .catch((err) => {
        // MessageErr("Có lỗi xảy ra. Vui lòng thử lại!");
        setStatusCheckOrder(6);
      });
  };

  const UploadOrder = (e) => {
    setLoading(true);
    const order = Helper.getParamUrlByKey("orderCode");
    const data = { images: listImg };
    if (registeredType === 1 && listImg.invoices.length === 0) {
      message.error("Vui lòng bổ sung hoá đơn chứng từ!");
      return;
    }
    if (registeredType === 3 && listImg.certificate.length === 0) {
      message.error("Vui lòng bổ sung hoá đơn chứng từ!");
      return;
    }
    Service.apicall(
      "POST",
      `api/v1/product/phy-damage-cov/${order}/upload-photo`,
      data
    )
      .then((res) => {
        setLoading(false);
        if (res?.data?.statusCode === 200) {
          setIsModalSuccess(true);
          setIsModalWarning(false);
        } else {
          setMessErrApi(res?.data?.message);
          setIsModalVisibleFail(true);
          setIsModalWarning(false);
        }
      })
      .catch((err) => {
        setIsModalVisibleFail(true);
        setLoading(false);
        setIsModalWarning(false);
      });
  };

  const PageView = () => {
    switch (step) {
      case 0:
        return <Validate />;
      case 1:
        return <FrontLeft />;
      case 2:
        return <FrontRight />;
      case 3:
        return <BackRight />;
      case 4:
        return <BackLeft />;
      case 5:
        return <InspectionStamps />;
      case 6:
        return <Speedometer />;
      case 7:
        return <ViewAndComplete />;
      // chụp hình ảnh thông tin xe và giấy tờ xe
      case 8:
        return <VehicleRegistration />;
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const setListImgG2 = (linkG2, keyName) => {
    if (keyName === "other") {
      const other2 = other;
      other2.push(linkG2);
      setListIMG({ ...listImg, other: other });
    } else if (keyName === "invoices" || keyName === "certificate") {
      const other2 = documentCar;
      other2.push(linkG2);
      setListIMG({ ...listImg, [keyName]: documentCar });
    } else {
      setListIMG({ ...listImg, [keyName]: linkG2 });
    }
  };

  const DeleteOther = (index) => {
    const other2 = other.splice(index, 1);
    setListIMG({ ...listImg, other: other });
    // console.log("other", other2);
  };
  const DeleteDocumentCar = (index, keyName) => {
    const other2 = documentCar.splice(index, 1);
    setListIMG({ ...listImg, [keyName]: documentCar });
    // console.log("other", other2);
  };

  // Bắt đầu nén hình
  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const resizeMe = (img) => {
    var canvas = document.createElement("canvas");
    var width = img.width;
    var height = img.height;
    const max_width = 1200;
    const max_height = 1200;
    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        height = Math.round((height *= max_width / width));
        width = max_width;
      }
    } else {
      if (height > max_height) {
        width = Math.round((width *= max_height / height));
        height = max_height;
      }
    }

    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    // Compress jpg, 70%
    return canvas.toDataURL("image/jpeg", 0.7);
  };

  const compressIMG = (file, name, keyName) => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (event) => {
      var blob = new Blob([event.target.result]);
      window.URL = window.URL || window.webkitURL;
      var blobURL = window.URL.createObjectURL(blob);
      const image = new Image();
      image.src = blobURL;
      image.onload = () => {
        const resized = resizeMe(image);
        // Download file test
        // const imageData = resized.replace("image/png", "image/octet-stream");
        // var link = document.createElement('a');
        // link.setAttribute('download', 'ResizeImage.jpg');
        // link.setAttribute('href', imageData);
        // link.click();
        // getValueImg(DataURIToBlob(resized));
        // console.log(DataURIToBlob(resized));
        uploadG2(DataURIToBlob(resized), name, keyName);
      };
    };
  };

  // Kết thúc nén hình

  // Úp hình lên g2 lấy link về
  const uploadG2 = (file, name, keyName) => {
    setLoading(true);
    const random = (Math.random() + 1).toString(36).substring(7);
    // Tên xe được lấy từ URL
    const carName = carNameOrder ? carNameOrder : random;
    const storage = Helper.getParamUrlByKey("storage")
      ? Helper.getParamUrlByKey("storage")
      : "s3";
    var form = new FormData();
    const timestamp = +new Date();
    form.append("storage", storage);
    form.append("file", file);
    form.append("path", `/user/${name}/${carName}/${timestamp}-${random}`);
    Service.apicall_upload_G2("POST", `upload`, form)
      .then((res) => {
        setLoading(false);
        const data = res.data;
        if (data.complete) {
          if (step < 8) {
            if (step !== 7) {
              setStep(step + 1);
            }
          } else {
            setStep(7);
          }
          setListImgG2(data.link, keyName);
        } else {
          MessageErr("Có lỗi xảy ra. Vui lòng chụp lại ảnh!");
        }
      })
      .catch((err) => {
        setLoading(false);
        MessageErr("Có lỗi xảy ra. Vui lòng chụp lại ảnh!");
      });
  };

  // Thông báo lỗi dủng chung cho các page
  const MessageErr = (content) => {
    message.error(
      {
        content: content,
        style: {
          marginTop: "25px",
        },
      },
      30
    );
  };

  const postMessage = (listIMGG2) => {
    if (listIMGG2) {
      window.parent.postMessage(
        {
          type: "dataImg",
          message: listIMGG2,
        },
        "*"
      );
    } else {
      window.parent.postMessage(
        {
          type: "openIframe",
          message: "close",
        },
        "*"
      );
    }
  };

  return (
    <AuthContext.Provider
      value={{
        compressIMG,
        setStep,
        listImg,
        postMessage,
        MessageErr,
        DeleteOther,
        statusCheckOrder,
        UploadOrder,
        isModalVisibleFail,
        setIsModalVisibleFail,
        isModalSuccess,
        setIsModalSuccess,
        messErrApi,
        isModalWarning,
        setIsModalWarning,
        registeredType,
        setRegisteredType,
        DeleteDocumentCar,
      }}
    >
      <MDHuongdan handleCancel={handleCancel} isModalVisible={isModalVisible} />
      <Spin
        indicator={antIcon}
        tip={
          <div style={{ color: "#FB7800" }}>
            Hình ảnh đang được xử lý.
            <br />
            Vui lòng chờ trong giây lát!
          </div>
        }
        wrapperClassName="spin-custom"
        spinning={loading}
      >
        {PageView()}
      </Spin>
      <Modal
        className="modal-terms"
        centered={true}
        footer={false}
        open={showConfirm}
        closable={false}
        zIndex={100}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <img src={warning} alt="warning" width={60} />
          <div style={{ fontWeight: "bold", textAlign: "justify" }}>
            <div>
              Vui lòng chụp ảnh xe trước khai thác để hoàn tất thủ tục mua bảo
              hiểm. Quý khách lưu ý:
            </div>
            <div style={{ marginTop: 10 }}>
              1. Các tổn thất đã có trước ngày hiệu lực bảo hiểm, công ty bảo
              hiểm sẽ không chịu trách nhiệm chi trả.
            </div>
            <div style={{ marginTop: 10 }}>
              2. Hình ảnh khai thác trước cấp đơn sẽ được hệ thống lưu trữ và
              làm cơ sở xem xét bồi thường sau này.
            </div>
            <div style={{ marginTop: 10 }}>
              3. Các thiết bị, bộ phận được gắn thêm trên xe phải được khai báo
              và nhà bảo hiểm chấp nhận mới được bảo vệ. Nếu không khai báo và
              không được bảo hiểm chấp thuận sẽ không được bảo hiểm.
            </div>
          </div>
          <div style={{ width: "80%" }}>
            <Button
              style={{
                width: "100%",
                borderRadius: "100px",
                background: "#009B4B",
                color: "#fff",
                padding: 8,
                height: "auto",
              }}
              onClick={() => setShowConfirm(false)}
            >
              Đã hiểu
            </Button>
          </div>
        </div>
      </Modal>
    </AuthContext.Provider>
  );
};

export default Index;
